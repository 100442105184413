$body-color: #808080;
$primary: #ec180b;
$secondary: #808080;

$btn-padding-y: 1em;
$btn-focus-box-shadow: 0px;
$btn-border-radius: 0px;
$btn-focus-width: 0px;
$btn-border-radius-sm: 0px;
$btn-border-radius-lg: 0px;

$input-border-width: 0px;
$input-border-radius: 0px;
$input-focus-box-shadow: 0px;

@import '~line-awesome/dist/line-awesome/css/line-awesome.min.css';
@import '~bootstrap/scss/bootstrap';

html,
body {
    font-family: Roboto, apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Arial, 'Noto Sans',
        'Liberation Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';

    background-color: #f7f6f4;
}

.btn-secondary {
    color: white;
}

.mf-form-check-input {
    display: flex;
    align-items: center;

    &.mf-big {
        input[type='radio'] {
            margin-left: 5px;
            margin-right: 10px;
            width: 1.5em;
            height: 1.45em;
            &::before {
                width: 0.7em;
                height: 0.7em;
            }
        }
        input[type='checkbox'] {
            margin-left: 5px;
            margin-right: 10px;
            width: 1.5em;
            height: 1.5em;
            &::before {
                width: 0.75em;
                height: 0.75em;
            }
        }
    }
    input[type='radio'] {
        /* Add if not using autoprefixer */
        -webkit-appearance: none;
        appearance: none;
        /* For iOS < 15 to remove gradient background */
        background-color: #fff;
        /* Not removed via appearance */
        margin: 0;
        margin-right: 0.5em;
        font: inherit;
        color: $secondary;
        width: 1.15em;
        height: 1.15em;
        border: 0.2em solid $secondary;
        border-radius: 50%;
        // transform: translateY(0.2em);

        display: grid;
        place-content: center;

        &::before {
            content: '';
            width: 0.65em;
            height: 0.65em;
            border-radius: 50%;
            transform: scale(0);
            transition: 120ms transform ease-in-out;
            box-shadow: inset 1em 1em $primary;
        }

        &:checked::before {
            transform: scale(1);
        }
    }
    input[type='checkbox'] {
        /* Add if not using autoprefixer */
        -webkit-appearance: none;
        appearance: none;
        /* For iOS < 15 to remove gradient background */
        background-color: #fff;
        /* Not removed via appearance */
        margin: 0;
        margin-right: 0.5em;
        font: inherit;
        color: $secondary;
        width: 1.15em;
        height: 1.15em;
        border: 0.2em solid $secondary;
        // transform: translateY(0.2em);

        display: grid;
        place-content: center;

        &::before {
            content: '';
            width: 0.65em;
            height: 0.65em;
            transform: scale(0);
            transition: 120ms transform ease-in-out;
            box-shadow: inset 1em 1em $primary;
        }

        &:checked::before {
            transform: scale(1);
        }
    }
}

.mf-form-check-input {
    input[type='radio']:checked + label {
        i {
            color: var(--bs-primary);
        }
        .mf-input-text {
            color: var(--bs-primary);
        }
    }
    input[type='checkbox']:checked + label {
        i {
            color: var(--bs-primary);
        }
        .mf-input-text {
            color: var(--bs-primary);
        }
    }
}

// input[type='radio']::before {
//     content: '';
//     width: 0.65em;
//     height: 0.65em;
//     border-radius: 50%;
//     transform: scale(0);
//     transition: 120ms transform ease-in-out;
//     box-shadow: inset 1em 1em $primary;
// }

// input[type='radio']:checked::before {
//     transform: scale(1);
// }

// .form-control + .form-control {
//     margin-top: 1em;
// }
